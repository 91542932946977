import { createSelector } from "reselect";
import { MeetingType, RootState } from "..";
import { DateTime } from "luxon";

export const selectMeetingLogPage = createSelector([
  (state: RootState) => state.cabUI.meetingLogPage,
  (state: RootState) => state.cabUI.pollResultsPage,
  (state: RootState) => state.cabUI.reusablePage,
  (state: RootState, meetingType: MeetingType) => meetingType,
], (meetingLogPage, pollResultsPage, reusablePage, meetingType) => {
  if (meetingType === MeetingType.POLL) return pollResultsPage;
  if (meetingType === MeetingType.REUSABLE) return reusablePage;
  return meetingLogPage;
});

export const selectMeetingLogFilter = createSelector([
  selectMeetingLogPage,
], (meetingLogPage) => {
  return {
    ...meetingLogPage.dataFilter,
    items: meetingLogPage.dataFilter.items.map((item) => {
      return 'type' in item && item.type === 'date'
        ? { ...item, value: DateTime.fromISO(item.value).toJSDate() }
        : item;
    })
  };
});

export const selectMeetingLogColumnSort = createSelector([
  selectMeetingLogPage,
], (meetingLogPage) => {
  return meetingLogPage.columnSort;
});

export const selectMeetingLogColumnHide = createSelector([
  selectMeetingLogPage,
], (meetingLogPage) => {
  return meetingLogPage.columnHide;
});
